$carousel-width: 100%;
$carousel-strip-width: 100%;
$flipper-top-position: -22px;
$flipper-right-position: 56px;
$flipper-next-right-position: 0.5px;
$flipper-width: 48px;
$flipper-disabled-opacity: 0.4;
$flipper-outline-offset: 2px;
$flipper-border-radius: 50%;
$flipper-border-color: transparent;
$flipper-height-m: 48px;
$flipper-width-m: 48px;

$vert-carousel-height: 100%;
$vert-carousel-strip-width: 100%;
$vert-flipper-height: 40px;
$carousel-control-hover-opacity: 0.9;

//for history
.history {
    margin-top: 30px;
    min-height: 100%;
    .msc-carousel {
        align-items: center;
        justify-content: center;
        .ms-content-block[data-m-layout="left-right"] {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
        }
    }
}
.msc-ss-carousel {
    position: relative;
    touch-action: pan-y pinch-zoom;
    width: $carousel-width;
    height: fit-content;

    .msc-flipper {
        @include flipper-container-decoration();
        position: absolute;

        .msi-chevron-left {
            @include add-icon($msv-ChevronLeft);
            @include flipper-icon-decoration();
        }

        .msi-chevron-right {
            @include add-icon($msv-ChevronRight);
            @include flipper-icon-decoration();
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            height: $flipper-height-m;
            width: $flipper-width-m;
        }
    }

    &__flipper {
        color: var(--msv-font-primary-color);
        background: inherit;
        border: $flipper-border-color;
        border-radius: $flipper-border-radius;
        left: auto;
        outline-offset: $flipper-outline-offset;
        right: $flipper-right-position;
        top: $flipper-top-position;
        width: $flipper-width;
        z-index: 1;
        transform: translate(0, -50%);
        cursor: pointer;
    }

    &__flipper:disabled {
        color: var(--msv-font-disabled-color);
    }

    &__flipper--next {
        right: $flipper-next-right-position;
    }

    &-strip {
        width: $carousel-strip-width;
        height: auto;
        position: relative;
        overflow: hidden;
    }

    &-slide {
        display: inline-block;
        left: 0;
        padding-inline-start: 0;
        position: relative;
        transition: left cubic-bezier(0.16, 1, 0.29, 0.99) 0.667s;
        white-space: nowrap;
    }
}

.msc-ss-carousel-vert {
    position: relative;
    width: auto;
    height: $vert-carousel-height;
    display: inline-block;
    touch-action: pan-x pinch-zoom;

    &-slide {
        white-space: nowrap;
        height: auto;
        position: relative;
    }

    &-item {
        display: block;
        white-space: normal;
    }

    &-strip {
        height: $vert-carousel-strip-width;
        width: auto;
        overflow: hidden;
    }

    &__flipper {
        color: var(--msv-font-primary-color);
        background: inherit;
        border: $flipper-border-color;
        border-radius: $flipper-border-radius;
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translate(-50%, 0);
        top: -($vert-flipper-height / 2);

        @include add-icon($msv-ChevronUp);

        &:disabled {
            opacity: $flipper-disabled-opacity;
        }
    }

    &__flipper--next {
        top: auto;
        bottom: -($vert-flipper-height / 2);

        @include add-icon($msv-ChevronDown);
    }
}
