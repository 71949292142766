.contact-form {
  @include font-content(var(--msv-font-weight-normal));
  margin: 2em auto;
  width: 80%;
  form {
    .fg{
      padding: 0 1em;
      display: inline-block;
      vertical-align: top;
    }

    .error {
      color: red;
      font-size: small;
    }

    textarea{
      @include form-control;
      border-color: #6b727a;
      height: 144px;
      width: 100%;
    }
    input[type="checkbox"] {
      display: inline-block;
      height: 24px;
      vertical-align: middle;
      width: 24px;
    }
    input,select {
      @include form-control;
      border-color: #6b727a;
    }
    .radio-button {
      @include form-input-radio;
    }
    .radio-input {
      @include form-input-radio-label;
      width: calc(100% - 50px);
    }

    label {
      @include form-input-label;
      margin-top: 1em;
      &.choice{
        width: 50%;
        vertical-align: middle;
        display: inline-block;
      }
      &.required::after{
        color: red;
        padding-left: .15em;
        content: "*";
      }
    }
    button{
      margin-top: 1em;
      @include primary-button;
      @include font-content-l;
      background-color: transparent;
      letter-spacing: normal;
      height: auto;


    }
  }
}

//   &__heading {
//     @include font-content(var(--msv-font-weight-normal), var(--msv-account-profile-edit-heading-font-size), $msv-line-height-xl);
//     color: var(--msv-account-profile-edit-heading-font-color);
//   }

//   &__account-item {
//     margin-top: $msv-account-profile-edit-account-item-margin-top;

//     &-input {
//       @include form-input-el(var(--msv-account-profile-edit-input-bg));
//       margin-top: $msv-account-profile-edit-input-margin-top;
//       width: 100%;
//     }
//   }

//   &__page-success {
//     @include validation-success(var(--msv-account-profile-alert-bg), var(--msv-account-profile-alert-border), var(--msv-account-profile-alert-font-color));
//     display: none;
//     margin-top: $msv-account-profile-edit-message-margin-top;
//     padding: $msv-account-profile-edit-message-padding;
//   }

//   &__page-error {
//     @include validation-error(var(--msv-account-profile-alert-bg),var(--msv-account-profile-alert-border), var(--msv-account-profile-alert-font-color));
//     display: none;
//     margin-top: $msv-account-profile-edit-message-margin-top;
//     padding: $msv-account-profile-edit-message-padding;
//   }

//   &__item-error {
//     @include font-content(var(--msv-font-weight-normal), var(--msv-account-profile-edit-error-font-size), $msv-line-height-m);
//     color: var(--msv-account-profile-edit-error-font-color);
//   }

//   &__save-button {
//     @include primary-button($bg-color: var(--msv-account-profile-edit-primary-btn-bg), $color: var(--msv-account-profile-edit-primary-btn-font-color), $border-color: var(--msv-account-profile-edit-primary-btn-border));
//     margin-top: $msv-account-profile-edit-button-margin-top;
//     width: 100%;
//   }

//   &__cancel-button {
//     @include secondary-button($bg-color: var(--msv-account-profile-edit-secondary-btn-bg), $color: var(--msv-account-profile-edit-secondary-btn-font-color), $border-color: var(--msv-account-profile-edit-secondary-btn-border));
//     margin-top: $msv-account-profile-edit-button-margin-top;
//     width: 100%;
//   }

//   &__verifying-modal,
//   &__verifying-modal-message {
//     display: none;
//   }

//   @media screen and (max-width: $msv-breakpoint-m) {
//     &__container {
//       width: $msv-account-profile-edit-width-m;
//     }
//   }
// }
