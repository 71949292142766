table {
  margin-top: 20px;
  width: 60%;
  border: none;
  border-collapse: collapse;
  tr {
    &:nth-child(even) td {
      background-color: $msv-gray-100;
    }
    &:hover td {
      background-color: $msv-secondary-hover;
    }
    td,
    th {
      border-collapse: collapse;
      padding: 0.5rem 0.5rem;
      text-align: left;
      a {
        color: $msv-primary;
        &:hover {
          color: black;
        }
      }
    }

    td {
      border: 1px solid $msv-gray-200;
    }

    th {
      border: 1px solid $msv-gray-200;
    }
  }
}
.thumbnail-container {
  text-align: center;

  padding-left: 0% !important;
  padding-right: 0% !important;
  table{
    margin-top:0px;
    width: 100%;
  }
  td {
    border: none;
  }
}
