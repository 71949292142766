.category-listing{
    .img-block-link {
        display: block;
        width: 80%;
        margin: auto;
       //border: 1px solid black;
         .msc_image {
            // border: 1px solid black;
             width:80%;
             margin: auto;
             height: 250px;
        }
        .img-block-link-text {
           display: block; 
           text-align: center;
            font-weight: bold;
            
        
        }
    }
    .img-block-module{
        margin-bottom: 20px;
    }
    
}