$rtl-flipper-top-position: -40px;
$rtl-ss-carousel-padding-top: -22px;
$rtl-flipper-right-position: 54%;
$rtl-flipper-button-right-position: 43%;


[dir="rtl"] {
    .msc-carousel {
        &__control__next {
            right: unset;
            left: 0;

            &__icon {
                @include add-icon($msv-ChevronLeft);
            }
        }

        &__control__prev {
            left: unset;
            right: 0;

            &__icon {
                @include add-icon($msv-ChevronRight);
            }
        }
    }

    .msc-ss-carousel {
        > button:nth-child(1) {
            right: $rtl-flipper-button-right-position;
        }

        .msc-flipper {
            position: absolute;
            right: $rtl-flipper-right-position;
        }

        &__flipper {
            right: 0;
            top: $rtl-flipper-top-position;

            .msi-chevron-left {
                @include add-icon($msv-ChevronRight);
            }

            .msi-chevron-right {
                @include add-icon($msv-ChevronLeft);
            }

            &--next {
                right: 42px;
                left: 0;
            }
        }

        &-slide {
            float: left;
            display: inline-table;
        }
    }
}
